<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modalDuplicar">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Mi información</h5>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    placeholder="Nombre"
                    v-model="userAuth.user.nombre"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="apellido">Apellido</label>
                  <input
                    type="text"
                    class="form-control"
                    id="apellido"
                    placeholder="Apellido"
                    v-model="userAuth.user.apellido"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="cedula">Cédula</label>
                  <input
                    type="text"
                    class="form-control"
                    id="cedula"
                    placeholder="Cedula"
                    v-model="userAuth.user.cedula"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="cargo">Cargo</label>
                  <input
                    type="text"
                    class="form-control"
                    id="cargo"
                    placeholder="Cargo"
                    v-model="userAuth.user.cargo"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="celular">Celular</label>
                  <input
                    type="text"
                    class="form-control"
                    id="celular"
                    placeholder="Celular"
                    v-model="userAuth.user.ceular"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="correo">Correo</label>
                  <input
                    type="text"
                    class="form-control"
                    id="correo"
                    placeholder="Correo"
                    v-model="userAuth.user.correo"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="contrasena">Contraseña</label>
                  <input
                    type="text"
                    class="form-control"
                    id="contrasena"
                    placeholder="Contraseña"
                    v-model="password"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="contrasena2">Repetir Contraseña</label>
                  <input
                    type="text"
                    class="form-control"
                    id="contrasena2"
                    placeholder="Repetir Contraseña"
                    v-model="password2"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="open"
              :disabled="estado"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="guardar"
              v-if="!estado"
            >
              Guardar
            </button>
            <button class="btn btn-success" type="button" disabled v-else>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Guardando...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  data() {
    return {
      estado: false,
      password: "",
      password2: "",
      errorCorreo: false,
      errorCorreoMessage: "",
    };
  },
  computed: {
    userAuth() {
      return this.$store.getters.getUserAuth;
    },
  },
  methods: {
    open() {
      this.estado = false;
      $("#modalDuplicar").modal("toggle");
    },
    async guardar() {
      if ((!this.password && !this.password2) || this.valid) {
        this.estado = true;
        let datos = {
          user_id: this.userAuth.user.id,
          nombre: this.userAuth.user.nombre,
          apellido: this.userAuth.user.apellido,
          cedula: this.userAuth.user.cedula,
          cargo: this.userAuth.user.cargo,
          celular: this.userAuth.user.celular,
          correo: this.userAuth.user.correo,
          password: this.password,
        };
        const { STATUS_CODE } = await this.$store.dispatch(
          "updateCliente",
          datos
        );
        if (STATUS_CODE != 1) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Error al actualizar los datos.",
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Se actualizaron sus datos.",
          });
        }
        await this.$store.dispatch("getAuth", this.userAuth.access_token);
        this.open();
        this.estado = false;
      }
    },
    valid() {
      if (this.password == this.password2) {
        this.errorCorreo = true;
        this.errorCorreoMessage = "La contreaseña no son iguales";
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="css">
.error-validate {
  color: #f0374c;
  font-size: 0.75rem;
}
</style>