<template>
  <section>
    <loading v-if="estadoloading" />
    <div
      class="row p-0 m-0 content-evaluaciones d-flex justify-content-center align-items-center"
      v-else
    >
      <div
        class="col-lg-11 col-xl-11 col-md-11 col-sm-11 col-11 border rounded shadow-sm p-0 espacio"
      >
        <div class="row p-0 m-0">
          <div class="col-lg-4 col-xl-4 bg-list d-none d-lg-block">
            <p class="text-center mt-5" v-if="talleres.length == 0">
              No hay talleres realizados
            </p>
            <ul class="list-taller px-3 mt-5">
              <li
                v-for="taller in talleres"
                :key="taller.id"
                class="cursor-pointer"
                @click="seleccionar(taller)"
              >
                <p class="mb-0">
                  {{ taller.nombre }}
                </p>
              </li>
            </ul>
            <div class="row p-0 m-0 d-flex justify-content-center my-3">
              <div class="col-8" v-if="taller">
                <button
                  class="btn btn-descarga"
                  @click="generarPdf"
                  v-if="!generar_pdf"
                >
                  Descargar informe
                </button>
                <button class="btn btn-descarga" type="button" disabled v-else>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Descargando...
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-xl-8 col-12" v-if="!loading_grafica">
            <h3 class="titulo-calificacion" v-if="taller">
              Calificaciones {{ taller }}
            </h3>
            <div class="row m-0 p-0" v-if="taller">
              <div class="col-12">
                <div class="row m-0 p-0">
                  <div
                    class="col-lg-5 col-xl-5 col-12 pt-5"
                    v-if="metodologia == 1"
                  >
                    <p class="mb-2 mt-4">
                      El 100% de la evaluación se compone por la suma de tres
                      aspectos fundamentales:
                    </p>
                    <p class="m-0">
                      <strong>1.</strong> 35% Comunicación no verbal / cuerpo
                      (expresión corporal).
                    </p>
                    <p class="m-0">
                      <strong>2.</strong> 15% Comunicación no verbal / voz
                      (expresión vocal).
                    </p>
                    <p class="m-0">
                      <strong>3.</strong> 50% Comunicación verbal / mensaje
                      (contenido del mensaje).
                    </p>
                  </div>
                  <div class="col-lg-5 col-xl-5 col-12 pt-5" v-else>
                    <p class="mb-2 mt-4">
                      El 100% de {{ taller }} se compone por la suma de tres
                      aspectos fundamentales:
                    </p>
                    <p
                      class="m-0"
                      v-for="(m, index) in metodologia_nueva"
                      :key="index"
                    >
                      <strong>{{ index + 1 }}.</strong> {{ m.porcentaje }}%
                      {{ m.nombre }}
                    </p>
                  </div>
                  <div
                    class="col-lg-7 col-xl-7 col-12 p-0"
                    style="overflow: hidden"
                  >
                    <v-chart :options="bar" :autoresize="true" />
                  </div>
                </div>
              </div>
              <div class="col-12 my-2">
                <div class="row p-0 m-0">
                  <div
                    class="align-items-center col-12 col-lg-6 col-xl-6 d-flex"
                  >
                    <a
                      :href="userAuth.user.url_dropbox"
                      target="_blank"
                      class="mb-0"
                    >
                      <strong
                        >Ingresa a MIS RECURSOS para conocer tus oportunidades
                        de mejora</strong
                      >
                    </a>
                  </div>
                  <div class="col-lg-6 col-xl-6 col-12">
                    <img
                      :src="`${url}logo_empresas/${userAuth.user.foto_empresa}`"
                      alt
                      srcset
                      height="120"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="align-items-center col-12 col-lg-8 col-xl-8 d-flex justify-content-center"
            v-else
          >
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ECharts from "vue-echarts";
import echarts from "echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import v_select from "vue-select";
import loading from "@/components/Loading";
export default {
  name: "mi_evaluacion",
  data() {
    return {
      taller: "",
      taller_select: "",
      metodologia: "",
      metodologia_nueva: [],
      generar_pdf: false,
      loading_grafica: false,
      url: process.env.VUE_APP_RUTA_IMG,
      bar: {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `${params.name[0]} <br> ${params.value} %`;
          },
        },
        grid: {
          left: "10%",
          right: "30%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: {
          data: [
            {
              type: "category",
              value: ["Mensaje"],
              textStyle: {
                fontSize: 20,
                color: "#002854",
                fontFamily: "Arial",
              },
            },
            {
              type: "category",
              value: ["Voz"],
              textStyle: {
                fontSize: 20,
                color: "#002854",
                fontFamily: "Arial",
              },
            },
            {
              type: "category",
              value: ["Cuerpo"],
              textStyle: {
                fontSize: 20,
                color: "#002854",
                fontFamily: "Arial",
              },
            },
          ],
        },
        series: [
          {
            type: "bar",
            label: {
              normal: {
                position: "right",
                show: true,
                fontSize: 20,
                formatter: function (params) {
                  return `${params.value} %`;
                },
              },
            },
            itemStyle: {
              emphasis: {
                barBorderRadius: [50, 50],
              },
              normal: {
                barBorderRadius: [0, 50, 50, 0],
                color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#003D98",
                  },
                  {
                    offset: 1,
                    color: "#001F4A",
                  },
                ]),
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  components: {
    v_select,
    loading,
    "v-chart": ECharts,
  },
  computed: {
    userAuth() {
      return this.$store.getters.getUserAuth;
    },
    talleres() {
      return this.$store.getters.getTalleresCliente;
    },
    estadoloading() {
      return this.$store.getters.getEstatusLoading;
    },
  },
  methods: {
    async generarPdf() {
      this.generar_pdf = true;
      let token = JSON.parse(atob(localStorage.getItem("usuario")));
      const response = await fetch(
        `${process.env.VUE_APP_WEBSERVICE_URL}/informe/${this.taller_select}/${this.userAuth.user.id}/2/generar?token=${token.access_token}`
      );
      const { STATUS_CODE, DATA } = await response.json();
      if (STATUS_CODE) {
        var a = document.createElement("a");
        var url =
          process.env.VUE_APP_WEBSERVICE_URL +
          "/archivo/" +
          DATA +
          "/descargar";
        a.href = url;
        a.download;
        a.target = "_blank";
        a.click();
        window.URL.revokeObjectURL(url);
      }
      this.generar_pdf = false;
    },
    async seleccionar(data) {
      this.taller = data.nombre;
      this.loading_grafica = true;
      this.bar.series[0].data = [];
      this.taller_select = data.id;
      let datos = {
        user_id: this.userAuth.user.id,
        taller_id: data.id,
        metodologia: data.metodologia,
      };
      const { DATA } = await this.$store.dispatch("fetchGrafica", datos);
      if (data.metodologia == 0) {
        this.metodologia = 1;
        this.bar.yAxis.data = [
          {
            type: "category",
            value: ["Mensaje"],
            textStyle: {
              fontSize: 20,
              color: "#002854",
              fontFamily: "Arial",
            },
          },
          {
            type: "category",
            value: ["Voz"],
            textStyle: {
              fontSize: 20,
              color: "#002854",
              fontFamily: "Arial",
            },
          },
          {
            type: "category",
            value: ["Cuerpo"],
            textStyle: {
              fontSize: 20,
              color: "#002854",
              fontFamily: "Arial",
            },
          },
        ];
        this.bar.series[0].data.push(DATA.forma);
        this.bar.series[0].data.push(DATA.voz);
        this.bar.series[0].data.push(DATA.cuerpo);
        this.loading_grafica = false;
      } else {
        this.bar.yAxis.data = [];
        this.bar.grid = {
          left: "10%",
          right: "20%",
          bottom: "3%",
          containLabel: true,
        };
        this.metodologia_nueva = DATA;
        this.metodologia = 0;
        for (let index = 0; index < DATA.length; index++) {
          const element = DATA[index];
          this.bar.yAxis.data.push({
            type: "category",
            value: [element.nombre],
            textStyle: {
              fontSize: 16,
              color: "#002854",
              fontFamily: "Arial",
            },
          });
          this.bar.series[0].data.push(element.total);
        }
        this.loading_grafica = false;
      }
    },
    chartValueForma(grafica) {
      switch (grafica.subtipo) {
        case 1:
          if (grafica.fase == 1) {
            const result = this.puntaje(1, 1, 35);
            this.bar.series[0].data = [];
            this.bar.series[0].data.push(result.toFixed(2));
          } else {
            const result = this.puntaje(1, 2, 35);
            this.bar.series[1].data = [];
            this.bar.series[1].data.push(result.toFixed(2));
          }
          break;
        case 2:
          if (grafica.fase == 1) {
            const result = this.puntaje(2, 1, 15);
            this.bar2.series[0].data = [];
            this.bar2.series[0].data.push(result.toFixed(2));
          } else {
            const result = this.puntaje(2, 2, 15);
            this.bar2.series[1].data = [];
            this.bar2.series[1].data.push(result.toFixed(2));
          }
          break;
        case 3:
          if (grafica.fase == 1) {
            const result = this.puntaje(3, 1, 50);
            this.bar3.series[0].data = [];
            this.bar3.series[0].data.push(result.toFixed(2));
          } else {
            const result = this.puntaje(3, 2, 50);
            this.bar3.series[1].data = [];
            this.bar3.series[1].data.push(result.toFixed(2));
          }
          break;
        default:
          break;
      }
    },

    puntaje(subtipo, fase, porcentaje) {
      let suma = 0,
        total = 0;
      for (let index = 0; index < this.resultados.length; index++) {
        const element = this.resultados[index];
        if (
          element.subtipo == subtipo &&
          element.user_id == this.participante.id &&
          element.fase == fase
        ) {
          suma += element.puntaje;
        }
      }
      if (suma != 0) {
        total = (suma * porcentaje) / 100;
        console.log(
          (total * porcentaje) / this.puntajeOptimo(subtipo, porcentaje)
        );
        return (total * porcentaje) / this.puntajeOptimo(subtipo, porcentaje);
      }
    },
    puntajeOptimo(subtipo, porcentaje) {
      let suma = 0,
        resultado = 0;
      for (let index = 0; index < this.evaluar.aspectos.length; index++) {
        const element = this.evaluar.aspectos[index];
        if (element.subtipo == subtipo) {
          suma += element.subaspectos.length;
        }
      }
      resultado = (suma * 10 * porcentaje) / 100;
      return resultado.toFixed(2);
    },
  },
  mounted() {
    this.$store.dispatch("fetchTalleresCliente", this.userAuth.user.id);
  },
};
</script>
<style lang="css">
.echarts {
  height: 300px;
}
.content-evaluaciones {
  height: calc(100vh - 165px);
}
.bg-list {
  background: #f8f8f8;
}

.list-taller {
  padding: 0;
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  background: #f8f8f8;
}

/* Estilos track de scroll */
.list-taller::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.list-taller::-webkit-scrollbar-track:hover,
.list-taller::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.list-taller li {
  list-style: none;
  text-align: left;
  margin-top: 10px;
  border-bottom: 1px solid #002854;
  padding: 0 10px;
}

.list-taller li p {
  color: #002854;
  font-size: 1.2rem;
  padding-bottom: 10px;
}

.btn-descarga {
  color: #fff;
  background-color: #002854;
  border-color: #002854;
}

.btn-descarga:hover {
  background: rgba(0, 40, 84, 0.85);
  color: #fff;
}

.btn-descarga {
  box-shadow: none;
}

.titulo-calificacion {
  color: #002854;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-top: 20px;
}

@media (min-width: 300px) and (max-width: 500px) {
  .echarts {
    width: 300px !important ;
    height: 200px !important ;
  }
}

@media (max-width: 576px) {
  .echarts {
    width: 500px;
    height: 300px;
  }
}
@media (max-width: 992px) {
  .espacio {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #002854;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #002854 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>