<template>
  <section
    class="content-perfil row p-0 m-0 d-flex align-items-center justify-content-center"
  >
    <div class="col-10 col-sm-9 col-md-9 col-lg-6 col-xl-6 card shadow-sm">
      <div class="card-body">
        <div class="row p-0 m-0">
          <div class="col-12 d-flex justify-content-end">
            <span class="cursor-pointer" @click="configOpen">
              <font-awesome-icon icon="cog" class="setings" />
            </span>
          </div>
          <div
            class="row justify-content-end content-body p-0 m-0 position-relative"
          >
            <div class="foto-content cursor-pointer" @click="open">
              <input
                type="file"
                class="d-none"
                @change="uploadFile"
                id="foto"
              />
              <img
                :src="
                  !userAuth.user.foto
                    ? 'https://cdn.pixabay.com/photo/2012/04/26/19/43/profile-42914_960_720.png'
                    : `${url}fotos/${userAuth.user.foto}`
                "
                alt=""
                srcset=""
                class="foto"
                v-if="!estado_foto"
              />
              <div
                v-else
                class="foto d-flex justify-content-center align-items-center bg-white"
              >
                <div class="d-flex justify-content-center">
                  <div
                    class="spinner-border"
                    style="width: 3rem; height: 3rem"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-10 col-sm-9 col-md-9 col-lg-9 mt-5"
              style="border-bottom: 1px solid #001f4a"
            >
              <p
                class="text-center mb-1 font-weight-bold tipo-font text-nombre"
              >
                {{ userAuth.user.nombre }} {{ userAuth.user.apellido }}
              </p>
            </div>
            <div class="col-10 col-sm-9 col-md-9 col-lg-9">
              <p
                class="text-center pt-1 font-weight-bold tipo-font"
                style="color: #7a7a7a"
              >
                Nombre del vocero
              </p>
            </div>
            <div
              class="col-10 col-sm-9 col-md-9 col-lg-9 mt-5"
              style="border-bottom: 1px solid #001f4a"
            >
              <p
                class="text-center mb-1 font-weight-bold tipo-font text-correo"
              >
                {{ userAuth.user.correo }}
              </p>
            </div>
            <div class="col-10 col-sm-9 col-md-9 col-lg-9">
              <p
                class="text-center pt-1 font-weight-bold tipo-font"
                style="color: #7a7a7a"
              >
                Correo
              </p>
              <p
                class="text-center text-danger"
                v-if="userAuth.user.nuevo === 1"
              >
                Debes actualizar la contraseña.
              </p>
            </div>

            <div class="col-lg-9 text-center mt-3 foto-footer">
              <img
                :src="`${url}logo_empresas/${userAuth.user.foto_empresa}`"
                alt
                srcset
                height="120"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalCliente ref="cliente" />
  </section>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import modalCliente from "@/components/modalCliente";
export default {
  name: "mi_perfil",
  data() {
    return {
      foto: "",
      url: process.env.VUE_APP_RUTA_IMG,
      estado: false,
      estado_foto: "",
      tamaño_navegador: 0,
    };
  },
  components: {
    modalCliente,
  },
  computed: {
    userAuth() {
      return this.$store.getters.getUserAuth;
    },
  },
  methods: {
    async updateCliente() {
      this.estado = true;
      let datos = {
        nombre: this.userAuth.user.nombre,
        apellido: this.userAuth.user.apellido,
        cedula: this.userAuth.user.cedula,
        celular: this.userAuth.user.celular,
        genero: this.userAuth.user.genero,
        cargo: this.userAuth.user.cargo,
        correo: this.userAuth.user.correo,
        user_id: this.userAuth.user.id,
      };
      const { STATUS_CODE } = await this.$store.dispatch(
        "updateCliente",
        datos
      );
      if (STATUS_CODE == 1) {
        await this.$store.dispatch("refreshUser");
        this.estado = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "Se actualizo su informacion.",
        });
      } else {
        this.estado = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Error al actualizar informacion.",
        });
      }
    },
    open() {
      $("#foto").click();
    },
    configOpen() {
      this.$refs.cliente.open();
    },
    async uploadFile(e) {
      let posicion = e.target.files[0].name.indexOf(".");
      let extension = e.target.files[0].name.substring(posicion + 1);
      let valido = ["png", "jpg", "jpeg"];

      if (valido.includes(extension)) {
        this.estado_foto = true;
        let element = this;
        const { STATUS_CODE } = await this.$store.dispatch(
          "uploadFileCliente",
          {
            archivo: e.target.files[0],
            user_id: this.userAuth.user.id,
          }
        );
        await this.$store.dispatch("getAuth", this.userAuth.access_token);
        this.estado_foto = false;
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "No es una imagen.",
        });
      }
    },
  },
  mounted() {},
};
</script>
<style lang="css">
.content-perfil {
  height: calc(100vh - 165px);
}
.setings {
  font-size: 1.5rem;
  color: #7a7a7a;
}
.foto-content {
  position: absolute;
  left: -22%;
  top: 25px;
}
.foto {
  border: 2px solid #081f48;
  border-radius: 50%;
  width: 260px;
  height: 250px;
}

.text-nombre {
  color: #001f4a;
  font-size: 1.5rem;
}
.text-correo {
  color: #001f4a;
  font-size: 1.5rem;
}
.content-body {
  padding-right: 3rem !important;
  justify-content: end;
}
@media (max-width: 767px) {
  .card-body,
  .card {
    padding: 0 !important;
  }

  .card {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .foto-content {
    position: relative;
    width: 100%;
    text-align: center;
    right: 0;
    left: 0;
  }

  .text-correo,
  .text-nombre {
    font-size: 1rem !important;
  }

  .setings {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #7a7a7a;
  }

  .content-body {
    padding-right: 0rem !important;
    justify-content: center !important;
  }

  .foto {
    width: 110px !important;
    height: 100px !important;
  }
  .foto-footer {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .card-body,
  .card {
    padding: 0 !important;
  }

  .card {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .foto-content {
    position: relative;
    width: 100%;
    text-align: center;
    right: 0;
    left: 0;
  }

  .text-correo,
  .text-nombre {
    font-size: 1.4rem !important;
  }

  .setings {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #7a7a7a;
  }

  .content-body {
    padding-right: 0rem !important;
    justify-content: center !important;
  }

  .foto {
    width: 160px !important;
    height: 150px !important;
  }
  .foto-footer {
    margin-bottom: 15px;
  }
}
</style>